import React, { useContext, useState } from "react";
import {
  notEmptyValidator,
  regexpValidator,
  validateForm,
  validateFormOptional,
} from "../../utils/FormManager";
import { AppContext } from "../../utils/AppContext";
import { IReservation } from "../common/types";
import { TextField } from "./components/TextField";
import { KeyRound, MapPinHouse, Signpost } from "lucide-react";
import { PrimaryButton } from "./components/PrimaryButton";

const name2validators = {
  roat_number: [notEmptyValidator(), regexpValidator(new RegExp(/\s*[^-]+-(\d{6})\s*/,'i'), 'The correct HUT shall be 6 digits, in the format HUT5-000000')],
  address: [notEmptyValidator()],
  postal_code_city: [notEmptyValidator()],
};

interface IAccomodationEditProps {
  idx: number;
  accomodation: Partial<IReservation>;
  setAccomodation: (x: any) => void;
  onSave: () => void;
  onDelete: () => void;
  saved: boolean;
  onBadSave?: (() => void) | null;
}

interface IFormData {
  roat_number: string;
  address: string;
  postal_code_city: string;
  room_code: string;
}

export function AccomodationEdit({
  idx,
  accomodation,
  setAccomodation,
  onSave,
  onDelete,
  saved,
  onBadSave = null,
}: IAccomodationEditProps) {
  const { getText } = useContext(AppContext);

  // const [formData, setFormData] = useState<IFormData>({
  //   roat_number: accomodation["roat_number"],
  //   address: accomodation["address"],
  //   postal_code_city: accomodation["postal_code_city"],
  //   room_code: accomodation["room_code"],
  // });
  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});

  const [show, setShow] = useState<boolean>(false);

  const handleSave = async () => {
    const invalidFields = validateForm(accomodation, name2validators);
    if (invalidFields.length === 0) {
      onSave();
    } else {
      const parts = [];
      for (const nameMsg of invalidFields) {
        parts.push(nameMsg.join(": "));
      }
      if (onBadSave) {
        onBadSave();
      }
    }
  };

  const handleToggleShow = () => {
    setShow((prev) => !prev);
  };

  const handleChange = (name: string, value: string) => {
    const newData = { [name]: value };
    setAccomodation({ ...accomodation, ...newData });
    const newMsgs = validateFormOptional(newData, name2validators);
    // console.log(newMsgs);
    setFormMessages({ ...formMessages, ...newMsgs });
  };

  const renderBody = () => {
    return (
      <div className="control__wrap">
        <div style={{ width: "100%", position: "relative" }}>
          <div
            className="btn"
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              minWidth: "auto",
              alignSelf: "center",
              justifySelf: "flex-end",
              fontSize: "14px",
            }}
            onClick={() => onDelete()}
          >
            <img
              src="/images/delete.svg"
              alt="delete"
              className="filter_gray"
              style={{ width: "1.2em" }}
            />
          </div>
        </div>
        <div className="form__control">
          <TextField
            icon={<KeyRound className="h-5 w-5 text-gray-400" />}
            label={getText("l_roat_number")}
            placeholder={getText("ph_roat_number")}
            type="text"
            value={accomodation.roat_number ?? ''}
            onChange={(v) => handleChange("roat_number", v)}
            messages={formMessages.roat_number}
          />
        </div>
        <div className="form__control">
          <TextField
            icon={<MapPinHouse className="h-5 w-5 text-gray-400" />}
            label={getText("l_address")}
            placeholder={getText("ph_address")}
            type="text"
            value={accomodation.address ?? ''}
            onChange={(v) => handleChange("address", v)}
            messages={formMessages.address}
          />
        </div>

        <div className="form__control">
          <TextField
            icon={<Signpost className="h-5 w-5 text-gray-400" />}
            label={getText("l_postal_code_city")}
            placeholder={getText("ph_postal_code_city")}
            type="text"
            value={accomodation.postal_code_city ?? ''}
            onChange={(v) => handleChange("postal_code_city", v)}
            messages={formMessages.postal_code_city}
          />
        </div>

        <div className="form__control" style={{ width: "100%" }}>
          <label className="block text-sm font-medium text-gray-700">
            {getText("l_room_code")}
          </label>
          <div className="mt-1 relative">
            <p className="block w-full border-gray-300 rounded-md focus:ring-[#E47E7B] focus:border-[#E47E7B]" style={{borderWidth: "1px", padding: '0.5rem 1rem'}}>
              {accomodation.room_code
                ? accomodation.room_code
                : getText("ph_no_room_code")}
            </p>
          </div>
        </div>

        {!saved && (
          <div className="control_center">
            <PrimaryButton handleClick={handleSave}>
              {getText("btn_save")}
            </PrimaryButton>
          </div>
        )}
      </div>
    );
  };

  return (
    <div key={accomodation["id"]} className="form-container w520_ifw">
      <div className="form-card">
        <div
          className="card__header form__control"
          style={{
            display: "flex",
            flexDirection: "row",
            alignContent: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
          onClick={() => handleToggleShow()}
        >
          <h3>{accomodation.address}</h3>
          <div
            className="btn"
            style={{
              minWidth: "auto",
              padding: "0",
              alignSelf: "center",
              justifySelf: "flex-end",
              fontSize: "14px",
            }}
          >
            <img
              src={
                show ? "/images/triangle-up.svg" : "/images/triangle-down.svg"
              }
              alt="expand"
              style={{ width: "1.2em" }}
            />
          </div>
        </div>
        {show && renderBody()}
      </div>
    </div>
  );
}
