import React from "react";
import {
  ToggleLeft as Toggle,
  MapPin,
  Hash,
  Barcode,
  Building2,
  Users,
  Edit,
} from "lucide-react";
import { IReservation } from "../../common/types";

// interface PropertyCardProps {
//   reservation: IReservation;
//   onToggleActive: (id: number) => void;
// }

interface IPropertyCardEditProps {
  accomodation: any;
  // setAccomodation: (x: any) => void;
  // onSave: () => void;
  // onDelete: () => void;
  saved: boolean;
  // onBadSave?: (() => void) | null;
  onToggleActive: () => void;
  onEdit: () => void;
}

export function PropertyCardView({
  accomodation,
  saved,
  onToggleActive,
  onEdit,
}: IPropertyCardEditProps) {
  const canToggleActive =
    accomodation.id !== null && accomodation.id !== undefined;
  return (
    <div className="group relative overflow-hidden rounded-xl bg-white shadow-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-xl">
      {/* Status indicator strip */}
      <div
        className={`absolute left-0 top-0 h-full w-1.5 ${
          accomodation.active ? "bg-green-500" : "bg-red-500"
        }`}
      />

      <div className="p-6 pl-8">
        {/* Header */}
        <div className="flex items-start justify-between">
          <div>
            <div className="flex items-center gap-2">
              <Building2 className="h-5 w-5 text-[#E76D6D]" />
              <h3 className="text-xl font-bold text-gray-900">
                {accomodation.name}
              </h3>
            </div>
            {/* <div className="mt-1 flex items-center gap-2">
              <span
                className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                  accomodation.active
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800"
                }`}
              >
                {accomodation.active ? "● Active" : "● Inactive"}
              </span>
            </div> */}
            <div className="mt-1 flex items-center gap-2">
              <span
                className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
                  saved
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800"
                }`}
              >
                {saved ? "● Saved" : "● Not saved"}
              </span>
            </div>
          </div>
          {canToggleActive && (
            <button
              onClick={() => onToggleActive()}
              className={`flex items-center gap-2 rounded-lg px-4 py-2 text-sm font-medium transition-colors ${
                accomodation.active
                  ? "bg-green-50 text-green-700 hover:bg-green-100"
                  : "bg-red-50 text-red-700 hover:bg-red-100"
              }`}
            >
              <Toggle className="h-4 w-4" />
              {accomodation.active ? "Active" : "Inactive"}
            </button>
          )}
        </div>

        {/* accomodation Details */}
        <div className="mt-6 grid grid-cols-3 gap-4">
          <div className="flex items-center gap-2">
            <Hash className="h-4 w-4 text-gray-400" />
            <div>
              <p className="text-sm font-medium text-gray-500">HUT Number</p>
              <p className="font-mono text-gray-900">
                {accomodation.roat_number}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Barcode className="h-4 w-4 text-gray-400" />
            <div>
              <p className="text-sm font-medium text-gray-500">Internal Code</p>
              <p className="font-mono text-gray-900">
                {accomodation.room_code}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-2">
            <Users className="h-4 w-4 text-gray-400" />
            <div>
              <p className="text-sm font-medium text-gray-500">Max Travelers</p>
              <p className="text-gray-900">{6}</p>
            </div>
          </div>
        </div>

        <div className="mt-6 flex justify-between">
          {/* Address */}
          <div>
            <div className="flex items-center gap-2">
              <MapPin className="h-4 w-4 text-gray-400" />
              <p className="text-sm font-medium text-gray-500">Address</p>
            </div>
            <div className="mt-1 space-y-1 pl-6">
              <p className="text-gray-900">{accomodation.address}</p>
              <p className="text-gray-900">{accomodation.postal_code_city}</p>
            </div>
          </div>

          <button
            onClick={() => onEdit()}
            className={`flex items-center gap-2 rounded-lg px-4 py-2 text-sm font-medium transition-colors bg-orange-50 text-orange-700 hover:bg-orange-100`}
          >
            <Edit className="h-4 w-4" />
            Edit
          </button>
        </div>
      </div>
    </div>
  );
}
