import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { getTranslation } from "../utils/translations";
import { IAppContext } from "../utils/AppContext";
import { RequestManager } from "../utils/RequestManager";
import { AppContext } from "../utils/AppContext";
import { WEB_ROUTERS } from "../utils/routes";
import { NotFound } from "./NotFound";
import { Accomodations } from "./Customer/Accomodations";
import { Guide } from "./Home/Guide";
import { MyLoading, MyModal } from "./common/MyModal";
import { AdminLogin } from "./Admin/AdminLogin";
import { AdminDashboard2 } from "./Admin/AdminDashboard2";
import { THE_CODE_LINES } from "../utils/constants";
import { LoginPage } from "./Customer/LoginPage";
import { RegisterPage } from "./Customer/RegisterPage";
import { isMobile } from "../utils/helpers";
import { RoatCredentialsPage } from "./Customer/RoatCredentialsPage";
import { BatchRegistrationPage } from "./Customer/BatchRegistrationPage";
import { Legislation } from "./Home/Legislation";
import { AppDownload } from "./Home/AppDownload";
import { ReservationsPage } from "./Customer/ReservationsPage";
import { CustomerAccount } from "./Customer/CustomerAccount";
// import { TermsAndPrices } from "./Home/TermsAndPrices";
import { AdminReservationsPage } from "./Admin/AdminReservationsPage";
import { AdminAccomodations } from "./Admin/AdminAccomodations";
import { InvoicesPageCustomer } from "./Invoices/InvoicesPageCustomer";
import TouristRegistrationPage from "./Customer/TouristRegistration/TouristRegistrationPage";
import { Sidebar } from "./Sidebar";
import { FindReservation } from "./Tourist/Auth/FindReservation";
import { ForgotPassword } from "./Customer/Auth/ForgotPassword";
import { TouristApplication } from "./Tourist/Steps/TouristApplication";
import { LanguageSelector } from "./LanguageSelector";
import { InvoicesPage } from "./Invoices/InvoicesPage";
import { PaymentTest } from "./common/Stripe/PaymentTest";
import { SuccessPage, SuccessSubscriptionPage } from "./common/Stripe/SuccessPage";
import { Services } from "./Home/Services";
import { SubscriptionTest } from "./common/Stripe/SubscriptionTest";
import { PropertiesNew } from "./Customer/Properties/PropertiesNew";
// import { ISubscriptionStatus } from "./common/types";
import { InfoDialog } from "./Customer/TouristRegistration/components/InfoDialog";

const requestManager = new RequestManager("https://and.telsight.eu:82", false);
// const requestManager = new RequestManager("http://127.0.0.1:8000", true);
// const requestManager = new RequestManager("http://192.168.0.5:8000", true);
const allowedLanguages = ["english", "spanish", "french", "russian"];

// const languages = ["spanish", "french", "english", "russian"];

interface IMainProps {}
export const Main: React.FC<IMainProps> = () => {
  const [user, setUser] = useState(null);
  const [notification, setNotification] = useState("");
  // const [subscriptionStatus, setSubscriptionStatus] =
  //   useState<ISubscriptionStatus | null>(null);
  const [reservation, setReservation] = useState(null);
  const [reservationApplication, setReservationApplication] = useState(null);
  const [accomodationId, setAccomodationId] = useState(null); // NOTE: new for customer dashboard

  const [language, setLanguage] = useState<string>("english");
  // const [selectedLanguage, setSelectedLanguage] = useState<number>(languages.indexOf("english"));
  const [texts, setTexts] = useState<{ [key: string]: string }>({});

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);
  const [sidebar, setSidebar] = useState(true);
  // const [mobileBrowser, setMobileBrowser] = useState(false);

  const location = useLocation();
  const pathname = location.pathname;

  const navigate = useNavigate();

  useEffect(() => {
    setTexts(getTranslation("english"));
    if (isMobile.any()) {
      // setMobileBrowser(true);
      setSidebar(false);
    }
  }, []);

  useEffect(() => {
    if (!user) {
      requestManager.logout_();
    }
  }, [user]);

  const getText = (key: string) => {
    return texts[key];
  };

  const appContextValue: IAppContext = {
    requestManager: requestManager,
    user: user,
    setUser: setUser,
    notification,
    setNotification,
    // subscriptionStatus,
    // setSubscriptionStatus,
    reservation,
    setReservation,
    reservationApplication,
    setReservationApplication,
    accomodationId,
    setAccomodationId,
    language: language,
    setLanguage: setLanguage,
    getText: getText,
  };

  const handleSetLanguage = (lang: string) => {
    if (allowedLanguages.indexOf(lang) === -1) {
      alert("The language has not been implemented yet");
      if (allowedLanguages.indexOf(language) === -1) {
        lang = "english";
      } else {
        lang = language;
      }
    }
    // setSelectedLanguage(languages.indexOf(lang));

    setTexts(getTranslation(lang));
    setLanguage(lang);
  };

  const handleLogout = async () => {
    setLoading(true);
    const resp = await requestManager.logout();
    setUser(null);
    setLoading(false);
    if (resp.status === 200) {
      navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
    } else {
      setModalText("Failed to logout customer");
    }
  };
  const toggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const showCustomerNav = () => {
    const allowed = [
      WEB_ROUTERS.CUSTOMER_PROPERTIES,
      WEB_ROUTERS.CUSTOMER_RESERVATIONS,
      WEB_ROUTERS.CUSTOMER_IEAT,
      WEB_ROUTERS.CUSTOMER_DEPOSIT,
      WEB_ROUTERS.CUSTOMER_ACCOUNT,
      WEB_ROUTERS.CUSTOMER_INVOICES,
      WEB_ROUTERS.CUSTOMER_TOURIST_REGISTRATIONS,
    ];
    const show = allowed.reduce(
      (res, p) => res || pathname.indexOf(p) !== -1,
      false
    );
    return show;
  };
  const showAdminNav = () => {
    const allowed = [
      WEB_ROUTERS.ADMIN_MANAGERS,
      WEB_ROUTERS.ADMIN_PROPERTIES,
      WEB_ROUTERS.ADMIN_RESERVATIONS,
      WEB_ROUTERS.ADMIN_IEAT,
      WEB_ROUTERS.ADMIN_DEPOSIT,
      WEB_ROUTERS.ADMIN_ACCOUNT,
      WEB_ROUTERS.ADMIN_INVOICES,
    ];
    const show = allowed.reduce(
      (res, p) => res || pathname.indexOf(p) !== -1,
      false
    );
    return show;
  };
  const renderCustomerNav = () => {
    return (
      <nav className="nav nav_horizontal" style={{ paddingBottom: "32px" }}>
        <Link
          to={WEB_ROUTERS.CUSTOMER_PROPERTIES}
          className={
            pathname.indexOf(WEB_ROUTERS.CUSTOMER_PROPERTIES) !== -1
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/properties.svg"} alt="properties" />
          {getText("sb_properties")}
        </Link>
        <Link
          to={WEB_ROUTERS.CUSTOMER_RESERVATIONS}
          className={
            pathname.endsWith(WEB_ROUTERS.CUSTOMER_RESERVATIONS)
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/reservations.svg"} alt="reservations" />
          {getText("sb_reservations")}
        </Link>
        <Link
          to={WEB_ROUTERS.CUSTOMER_TOURIST_REGISTRATIONS}
          className={
            pathname.endsWith(WEB_ROUTERS.CUSTOMER_TOURIST_REGISTRATIONS)
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/reservations.svg"} alt="reservations" />
          {getText("sb_tourist_reservations")}
        </Link>
        <Link
          to={WEB_ROUTERS.CUSTOMER_IEAT}
          className={
            pathname.indexOf(WEB_ROUTERS.CUSTOMER_IEAT) !== -1
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/ieat.svg"} alt="ieat" />
          {getText("sb_ieat")}
        </Link>
        <Link
          to={WEB_ROUTERS.CUSTOMER_DEPOSIT}
          className={
            pathname.indexOf(WEB_ROUTERS.CUSTOMER_DEPOSIT) !== -1
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/deposit.svg"} alt="deposit" />
          {getText("sb_deposit")}
        </Link>
        <Link
          to={WEB_ROUTERS.CUSTOMER_ACCOUNT}
          className={
            pathname.indexOf(WEB_ROUTERS.CUSTOMER_ACCOUNT) !== -1
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/account.svg"} alt="account" />
          {getText("sb_account")}
        </Link>
        <Link
          to={WEB_ROUTERS.CUSTOMER_INVOICES}
          className={
            pathname.indexOf(WEB_ROUTERS.CUSTOMER_INVOICES) !== -1
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/invoices.svg"} alt="invoices" />
          {getText("sb_invoices")}
        </Link>
      </nav>
    );
  };

  const renderAdminNav = () => {
    return (
      <nav className="nav nav_horizontal" style={{ paddingBottom: "32px" }}>
        <Link
          to={WEB_ROUTERS.ADMIN_MANAGERS}
          className={
            pathname.indexOf(WEB_ROUTERS.ADMIN_MANAGERS) !== -1
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/account.svg"} alt="managers" />
          Managers
        </Link>
        <Link
          to={WEB_ROUTERS.ADMIN_PROPERTIES}
          className={
            pathname.indexOf(WEB_ROUTERS.ADMIN_PROPERTIES) !== -1
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/properties.svg"} alt="properties" />
          {getText("sb_properties")}
        </Link>
        <Link
          to={WEB_ROUTERS.ADMIN_RESERVATIONS}
          className={
            pathname.indexOf(WEB_ROUTERS.ADMIN_RESERVATIONS) !== -1
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/reservations.svg"} alt="reservations" />
          {getText("sb_reservations")}
        </Link>
        <Link
          to={WEB_ROUTERS.ADMIN_IEAT}
          className={
            pathname.indexOf(WEB_ROUTERS.ADMIN_IEAT) !== -1 ? "nav_active" : ""
          }
        >
          <img src={"/images/ieat.svg"} alt="ieat" />
          {getText("sb_ieat")}
        </Link>
        <Link
          to={WEB_ROUTERS.ADMIN_DEPOSIT}
          className={
            pathname.indexOf(WEB_ROUTERS.ADMIN_DEPOSIT) !== -1
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/deposit.svg"} alt="deposit" />
          {getText("sb_deposit")}
        </Link>
        <Link
          to={WEB_ROUTERS.ADMIN_ACCOUNT}
          className={
            pathname.indexOf("/admin/account") !== -1 ? "nav_active" : ""
          }
        >
          <img src={"/images/account.svg"} alt="account" />
          {getText("sb_account")}
        </Link>
        <Link
          to={WEB_ROUTERS.ADMIN_INVOICES}
          className={
            pathname.indexOf(WEB_ROUTERS.ADMIN_INVOICES) !== -1
              ? "nav_active"
              : ""
          }
        >
          <img src={"/images/invoices.svg"} alt="invoices" />
          {getText("sb_invoices")}
        </Link>
      </nav>
    );
  };

  const showManagersBtn =
    [
      WEB_ROUTERS.ADMIN_MANAGERS,
      WEB_ROUTERS.ADMIN_RESERVATIONS,
      WEB_ROUTERS.ADMIN_DEPOSIT,
      WEB_ROUTERS.ADMIN_IEAT,
      WEB_ROUTERS.ADMIN_ACCOUNT,
      WEB_ROUTERS.ADMIN_INVOICES,
    ].indexOf(pathname) !== -1;

  return (
    <AppContext.Provider value={appContextValue}>
      <div className={"main-screen " + (sidebar ? "" : "hide_sidebar")}>
        {/* TOPBAR */}

        <Sidebar />

        <div className="main_part">
          {/* TOPBAR */}
          <div className="topbar">
            <div style={{ padding: "8px" }} onClick={() => toggleSidebar()}>
              <img src={"/images/settings.svg"} alt="settings" />
            </div>
            <div className="topbar_content">
              <h2 className="topbar__header">{getText("h_topbar")}</h2>
              {(pathname === WEB_ROUTERS.ADMIN_DASHBOARD ||
                showManagersBtn) && (
                <div
                  className={
                    pathname === WEB_ROUTERS.ADMIN_DASHBOARD
                      ? "btn outline"
                      : "btn filled"
                  }
                  onClick={() => {
                    const nextRoute = showManagersBtn
                      ? WEB_ROUTERS.ADMIN_DASHBOARD
                      : WEB_ROUTERS.ADMIN_RESERVATIONS;
                    navigate(nextRoute);
                  }}
                >
                  {getText("btn_managers")}
                </div>
              )}
              <LanguageSelector
                value={language}
                onChange={(lang) => {
                  handleSetLanguage(lang);
                }}
              />
            </div>
            <div onClick={() => handleLogout()} className="topbar__logout">
              <img src="/images/logout.svg" alt="logout" />
            </div>
          </div>

          {/* MAIN CONTENT */}
          <div className="main-content">
            {/* {user && subscriptionStatus &&
              subscriptionStatus.active > subscriptionStatus.available && (
                <div
                  className={`flex flex-col gap-1 py-4 m-2 items-center rounded-full px-2.5 py-0.5 text-xs font-medium bg-red-100 text-red-800`}
                >
                  <p>{`Active properties: ${subscriptionStatus.active} / ${subscriptionStatus.available}`}</p>
                  <p>
                    Subscribe or deactivate some properties to continue
                    operations
                  </p>
                </div>
              )} */}
            {showCustomerNav() && renderCustomerNav()}
            {showAdminNav() && renderAdminNav()}
            <Routes>
              <Route
                path="/"
                element={<Navigate to={WEB_ROUTERS.TOURIST_RESERVATION} />}
              />
              <Route path={WEB_ROUTERS.USER_GUIDE} element={<Guide />} />
              {/* <Route path={WEB_ROUTERS.PRICES} element={<TermsAndPrices />} /> */}
              <Route path={WEB_ROUTERS.SERVICES} element={<Services />} />
              {/* <Route path={WEB_ROUTERS.TERMS} element={<Terms />} /> */}
              <Route
                path={WEB_ROUTERS.LEGISLATION_IN_FORCE}
                element={<Legislation />}
              />
              <Route
                path={WEB_ROUTERS.APP_DOWNLOAD}
                element={<AppDownload />}
              />
              <Route
                path={WEB_ROUTERS.TOURIST_RESERVATION}
                element={<FindReservation />}
              />
              <Route
                path={WEB_ROUTERS.TOURIST_GUESTS_STEPS}
                element={<TouristApplication />}
              />
              <Route
                path={WEB_ROUTERS.TOURIST_PAYMENT}
                element={<InvoicesPage />}
              />
              <Route
                path={WEB_ROUTERS.TOURIST_PAYMENT_SUCCESS_PAGE}
                element={<SuccessPage />}
              />
              {/* <Route
                path={WEB_ROUTERS.TOURIST_PAYMENT_CHECKOUT}
                element={<Checkout />}
              /> */}
              <Route
                path={WEB_ROUTERS.TOURIST_PAYMENT_TEST}
                element={<PaymentTest />}
              />
              <Route
                path={WEB_ROUTERS.CUSTOMER_SUBSCRIPTION_TEST}
                element={<SubscriptionTest />}
              />

              {/* <Route path={WEB_ROUTERS.TOURIST_GUESTS} element={<Guests />} /> */}
              <Route
                path={WEB_ROUTERS.CUSTOMER_LOGIN}
                element={<LoginPage />}
              />
              <Route
                path={WEB_ROUTERS.CUSTOMER_REGISTER}
                element={<RegisterPage />}
              />
              {/* <Route
                path={WEB_ROUTERS.CUSTOMER_PASSWORD_RESET}
                element={<PasswordReset />}
              /> */}
              <Route
                path={WEB_ROUTERS.CUSTOMER_PASSWORD_RESET}
                element={<ForgotPassword />}
              />
              {/* <Route
                path={WEB_ROUTERS.CUSTOMER_PROPERTIES}
                element={<Accomodations />}
              /> */}
              <Route
                path={WEB_ROUTERS.CUSTOMER_PROPERTIES}
                element={<PropertiesNew />}
              />
              <Route
                path={WEB_ROUTERS.CUSTOMER_RESERVATIONS}
                element={<ReservationsPage tableName="reservations" />}
              />
              {/* <Route
                path={WEB_ROUTERS.CUSTOMER_RESERVATIONS_NEW}
                element={<TouristRegistrationPage />}
              /> */}
              <Route
                path={WEB_ROUTERS.CUSTOMER_IEAT}
                element={<ReservationsPage tableName="ieat" />}
              />
              <Route
                path={WEB_ROUTERS.CUSTOMER_DEPOSIT}
                element={<ReservationsPage tableName="deposit" />}
              />
              <Route
                path={WEB_ROUTERS.CUSTOMER_ACCOUNT}
                element={<CustomerAccount />}
              />
              <Route
                path={WEB_ROUTERS.CUSTOMER_INVOICES}
                element={<ReservationsPage tableName="invoices" />}
              />
              <Route
                path={WEB_ROUTERS.CUSTOMER_PAYMENT}
                element={<InvoicesPageCustomer />}
              />
              <Route
                path={WEB_ROUTERS.CUSTOMER_TOURIST_REGISTRATIONS}
                element={<TouristRegistrationPage />}
              />
              <Route
                path={WEB_ROUTERS.CUSTOMER_PAYMENT_SUCCESS_PAGE}
                element={<SuccessSubscriptionPage />}
              />

              {/* <Route
                path={WEB_ROUTERS.CUSTOMER_DASHBOARD}
                element={<CustomerDashboardExt />}
              /> */}

              <Route path={WEB_ROUTERS.ADMIN_LOGIN} element={<AdminLogin />} />
              <Route
                path={WEB_ROUTERS.ADMIN_DASHBOARD}
                element={<AdminDashboard2 />}
              />
              <Route
                path={WEB_ROUTERS.ADMIN_MANAGERS}
                element={<AdminReservationsPage tableName="managers" />}
              />
              <Route
                path={WEB_ROUTERS.ADMIN_PROPERTIES}
                element={<AdminAccomodations />}
              />
              <Route
                path={WEB_ROUTERS.ADMIN_RESERVATIONS}
                element={<AdminReservationsPage tableName="reservations" />}
              />
              <Route
                path={WEB_ROUTERS.ADMIN_IEAT}
                element={<AdminReservationsPage tableName="ieat" />}
              />
              <Route
                path={WEB_ROUTERS.ADMIN_DEPOSIT}
                element={<AdminReservationsPage tableName="deposit" />}
              />
              <Route
                path={WEB_ROUTERS.ADMIN_ACCOUNT}
                element={<CustomerAccount />}
              />
              <Route
                path={WEB_ROUTERS.ADMIN_INVOICES}
                element={<AdminReservationsPage tableName="invoices" />}
              />

              <Route
                path={WEB_ROUTERS.CUSTOMER_ROAT}
                element={<RoatCredentialsPage />}
              />
              <Route
                path={WEB_ROUTERS.CUSTOMER_REGISTER_USER_BATCH}
                element={<BatchRegistrationPage />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
            {/* NOTE: */}
            <div className="the-code the-code_extra">
              {THE_CODE_LINES.map((t: string, i: number) => (
                <p key={i}>{t}</p>
              ))}
            </div>
          </div>
        </div>
        <InfoDialog
          isOpen={!!notification}
          title="Notification"
          message={notification ?? ""}
          onClose={() => setNotification("")}
          onConfirm={() => setNotification("")}
        />
        {modalText && (
          <MyModal>
            <div className="modal-text">{modalText}</div>
            <div className="btn bg_secondary" onClick={() => setModalText("")}>
              {getText("btn_ok")}
            </div>
          </MyModal>
        )}
        {loading && <MyLoading />}
      </div>
    </AppContext.Provider>
  );
};
