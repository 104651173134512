import React, { useContext, useState } from "react";
import { Mail, Lock, User, Phone } from "lucide-react";
import {
  validateForm,
  notEmptyValidator,
  lengthValidator,
  validateFormOptional,
  trimForm,
} from "../../../utils/FormManager";
import { API_URLS } from "../../../utils/RequestManager";
import { WEB_ROUTERS } from "../../../utils/routes";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../utils/AppContext";
import { TextField } from "../../common/components/TextField";
import { PrimaryButton } from "../../common/components/PrimaryButton";

const name2validators = {
  firstname: [notEmptyValidator()],
  lastname: [notEmptyValidator()],
  email: [notEmptyValidator()],
  phone: [notEmptyValidator()],
  password: [lengthValidator(8, 255)],
  password2: [lengthValidator(8, 255)],
};
const name2validatorsWithCode = {
  ...name2validators,
  code: [notEmptyValidator()],
};

interface IRegisterFormProps {
  setModalText: (msg: string) => void;
  setLoading: (loading: boolean) => void;
  onLoginClick: () => void;
}

export function RegisterForm({
  setModalText,
  setLoading,
  onLoginClick,
}: IRegisterFormProps) {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    password: "",
    password2: "",
    code: "",
  });
  const [formMessages, setFormMessages] = useState<{
    firstname: string[];
    lastname: string[];
    email: string[];
    phone: string[];
    password: string[];
    password2: string[];
    code: string[];
  }>({
    firstname: [],
    lastname: [],
    email: [],
    phone: [],
    password: [],
    password2: [],
    code: [],
  });

  const navigate = useNavigate();

  const { requestManager, setUser, getText } = useContext(AppContext);

  const checkData = (n2v = name2validators) => {
    const data: any = { ...formData };
    trimForm(data);
    // Object.keys(n2v).forEach((key: any) => {
    //   data[key] = (formData as any)[key].trim();
    // });
    const invalidFields = validateForm(data, n2v);
    if (invalidFields.length > 0) {
      const msgs = invalidFields.reduce((r: any, v) => {
        const key = v[0];
        if (!(key in r)) {
          r[key] = [];
        }
        r[key].push(v[1]);
        return r;
      }, {});
      setFormMessages(msgs);
      return null;
    }
    return data;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // Handle login logic here
    // console.log("Register:", formData);
    setLoading(true);

    const data = checkData();
    if (!data || data.password.trim() !== data.password2.trim()) {
      setLoading(false);
      return;
    }
    const res = await requestManager.register(
      data.email.trim(),
      data.password.trim(),
      data.firstname.trim(),
      data.lastname.trim(),
      data.phone.trim()
    );
    setLoading(false);
    if (res.status === 200) {
      setUser(res.data);
      setModalText(getText("msg_code_sent"));
    } else if (res.status === 409) {
      setModalText(getText("m_customer_exists_not_vefified"));
    } else if (res.status === 403) {
      setModalText(getText("m_customer_exists"));
      return;
    } else if (res.status === 422) {
      setModalText(getText("m_some_fields_invalid"));
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
  };

  const handleSubmitCode = async (e: React.FormEvent) => {
    e.preventDefault();
    // Handle login logic here
    // console.log("Code:", formData);
    setLoading(true);

    const data = checkData(name2validatorsWithCode);
    if (!data) {
      setLoading(false);
      return;
    }
    const res = await requestManager.postJson(
      API_URLS.USER_CODE_VERIFY,
      data,
      false
    );
    setLoading(false);
    if (res.status === 200) {
      setModalText(getText("m_try_login"));
      navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
    } else if (res.status === 404) {
      setModalText(getText("m_code_invalid"));
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
  };

  const handleRegisterUserBatch = () => {
    navigate(WEB_ROUTERS.CUSTOMER_REGISTER_USER_BATCH);
  };

  const handleChange = (name: string, value: string) => {
    const newData = { [name]: value };
    setFormData({ ...formData, ...newData });
    const newMsgs = validateFormOptional(newData, name2validators);
    // console.log(newMsgs);
    setFormMessages({ ...formMessages, ...newMsgs });
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <button
          type="button"
          onClick={handleRegisterUserBatch}
          className="text-sm text-[#E47E7B] hover:text-[#D16B68]"
        >
          {getText("btn_register_tourist_batch")}
        </button>
      </div>

      {/* firstname */}
      <TextField
        icon={<User className="h-5 w-5 text-gray-400" />}
        label={getText("t_firstname")}
        placeholder={getText("ph_firstname")}
        type="text"
        value={formData.firstname}
        onChange={(v) => handleChange("firstname", v)}
        messages={formMessages.firstname}
      />
      {/* lastname */}
      <TextField
        icon={<User className="h-5 w-5 text-gray-400" />}
        label={getText("t_lastname")}
        placeholder={getText("ph_lastname")}
        type="text"
        value={formData.lastname}
        onChange={(v) => handleChange("lastname", v)}
        messages={formMessages.lastname}
      />
      {/* email */}
      <TextField
        icon={<Mail className="h-5 w-5 text-gray-400" />}
        label={getText("l_email")}
        placeholder={getText("ph_email")}
        type="email"
        value={formData.email}
        onChange={(v) => handleChange("email", v)}
        messages={formMessages.email}
      />
      {/* phone */}
      <TextField
        icon={<Phone className="h-5 w-5 text-gray-400" />}
        label={getText("l_phone")}
        placeholder={getText("ph_phone")}
        type="phone"
        value={formData.phone}
        onChange={(v) => handleChange("phone", v)}
        messages={formMessages.phone}
      />
      {/* password */}
      <TextField
        icon={<Lock className="h-5 w-5 text-gray-400" />}
        label={getText("l_password")}
        placeholder={getText("ph_password")}
        type="password"
        value={formData.password}
        onChange={(v) => handleChange("password", v)}
        messages={formMessages.password}
      />
      <TextField
        icon={<Lock className="h-5 w-5 text-gray-400" />}
        label={getText("l_password2")}
        placeholder={getText("ph_password")}
        type="password"
        value={formData.password2}
        onChange={(v) => handleChange("password2", v)}
        messages={formMessages.password2}
      />
      {formData.password !== formData.password2 && (
        <p className="error-message">{getText("m_pass_not_match")}</p>
      )}
      <PrimaryButton type="submit">{getText("h_create_account")}</PrimaryButton>

      <div className="text-center">
        <span className="text-sm text-gray-600">
          {getText("m_already_have_account")}
        </span>
        &nbsp;
        <button
          type="button"
          onClick={onLoginClick}
          className="text-sm text-[#E47E7B] hover:text-[#D16B68]"
        >
          {getText("btn_sign_in")}
        </button>
      </div>

      {/* code */}
      <TextField
        icon={<Lock className="h-5 w-5 text-gray-400" />}
        label={getText("l_code")}
        placeholder={getText("ph_code")}
        type="text"
        value={formData.code}
        onChange={(v) => handleChange("code", v)}
        messages={formMessages.code}
      />
      {/* <div>
        <label className="block text-sm font-medium text-gray-700">
          {getText("l_code")}
        </label>
        <div className="mt-1 relative">
          <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
            <Lock className="h-5 w-5 text-gray-400" />
          </span>
          <input
            type="text"
            placeholder={getText("ph_code")}
            value={formData.code}
            onChange={(e) => handleChange("code", e.target.value)}
            className="pl-10 block w-full border-gray-300 rounded-md focus:ring-[#E47E7B] focus:border-[#E47E7B]"
          />
        </div>
        <div className="form__control">{getText("msg_code_sent")}</div>
        {formMessages.code?.map((m, i) => (
          <p key={i} className="error-message">
            {m}
          </p>
        ))}
      </div> */}

      <PrimaryButton
        type="submit"
        handleClick={(e: any) => handleSubmitCode(e)}
      >
        {getText("btn_start_checkin")}
      </PrimaryButton>
      {/* <button
        type="submit"
        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#E47E7B] hover:bg-[#D16B68] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#E47E7B]"
        onClick={handleSubmitCode}
      >
        {getText("btn_start_checkin")}
      </button> */}
    </form>
  );
}
