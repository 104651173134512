import React from 'react';
import { Mountain } from 'lucide-react';

export function Logo() {
  return (
    <div className="flex flex-col items-center">
      <div className="flex items-center">
        <Mountain className="h-10 w-10 text-[#E76D6D]" />
        <h1 className="ml-2 text-3xl font-bold text-[#E76D6D]">roatandorra</h1>
      </div>
      <p className="mt-1 text-sm uppercase tracking-widest text-gray-500">
        Obligations made simple
      </p>
    </div>
  );
}