import React from "react";
import { X } from "lucide-react";

export function MyLoading() {
  return (
    <React.Fragment>
      <div className="absoluteFill">
        <div className="loading-spinner"></div>
      </div>
    </React.Fragment>
  );
}

export function MyModal(props: any) {
  return (
    <React.Fragment>
      <div className="absoluteFill">
        <div className="modal-base">{props.children}</div>
      </div>
    </React.Fragment>
  );
}

interface IMyModalV2Props {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}
export function MyModalV2({ isOpen, onClose, children }: IMyModalV2Props) {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25"
          onClick={onClose}
        />
        <div className="relative bg-white rounded-lg shadow-xl max-w-2xl w-full">
          <button
            onClick={onClose}
            className="absolute right-4 top-4 text-gray-400 hover:text-gray-500"
          >
            <X className="h-6 w-6" />
          </button>
          {children}
        </div>
      </div>
    </div>
  );
}
