import React, { useContext, useState } from "react";
import { X } from "lucide-react";
import { IReservation } from "../../common/types";
import {
  notEmptyValidator,
  regexpValidator,
  validateForm,
  validateFormOptional,
} from "../../../utils/FormManager";
import { AppContext } from "../../../utils/AppContext";
import { TextField } from "../../common/components/TextField";
// import type { Property } from '../types';
// import { generateInternalCode } from '../utils/propertyUtils';

interface AddPropertyModalProps {
  idx: number;
  accomodation: Partial<IReservation>;
  setAccomodation: (x: any) => void;
  onSave: () => void;
  onClose: () => void;
  onDelete: () => void;
  saved: boolean;
  onBadSave?: (() => void) | null;
}

const name2validators = {
  roat_number: [
    notEmptyValidator(),
    regexpValidator(
      new RegExp(/\s*HUT[1-7]-\d{6}\s*/, "i"),
      "The correct HUT shall be 6 digits, in the format HUT5-000000"
    ),
  ],
  address: [notEmptyValidator()],
  postal_code_city: [notEmptyValidator()],
};

export function EditPropertyModal({
  idx,
  accomodation,
  setAccomodation,
  onSave,
  onClose,
  onDelete,
  saved,
  onBadSave = null,
}: AddPropertyModalProps) {
  const { getText } = useContext(AppContext);
  const [formMessages, setFormMessages] = useState<{ [key: string]: any }>({});
  const handleSave = async () => {
    const invalidFields = validateForm(accomodation, name2validators);
    if (invalidFields.length === 0) {
      onSave();
    } else {
      const parts = [];
      for (const nameMsg of invalidFields) {
        parts.push(nameMsg.join(": "));
      }
      if (onBadSave) {
        onBadSave();
      }
    }
  };

  const handleChange = (name: string, value: string) => {
    const newData = { [name]: value };
    setAccomodation({ ...accomodation, ...newData });
    const newMsgs = validateFormOptional(newData, name2validators);
    // console.log(newMsgs);
    setFormMessages({ ...formMessages, ...newMsgs });
  };

  const canDelete = accomodation.id !== null && accomodation.id !== undefined;
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="w-full max-w-2xl rounded-lg bg-white p-6">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold text-gray-900">Add New Property</h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="h-6 w-6" />
          </button>
        </div>

        <form className="mt-6">
          <div className="grid grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {getText("l_hut_number")}
              </label>
              <TextField
                icon={null}
                label={""}
                placeholder={getText("ph_roat_number")}
                type="text"
                value={accomodation.roat_number ?? ""}
                onChange={(v) => handleChange("roat_number", v)}
                messages={formMessages.roat_number}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                {getText("l_address")}
              </label>
              <TextField
                icon={null}
                label={""}
                placeholder={getText("ph_address")}
                type="text"
                value={accomodation.address ?? ""}
                onChange={(v) => handleChange("address", v)}
                messages={formMessages.address}
              />
            </div>
          </div>

          <div className="mt-6">
            <div className="mt-4 grid grid-cols-2 gap-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {getText("l_postal_code_city")}
                </label>
                <TextField
                  icon={null}
                  label={""}
                  placeholder={getText("ph_postal_code_city")}
                  type="text"
                  value={accomodation.postal_code_city ?? ""}
                  onChange={(v) => handleChange("postal_code_city", v)}
                  messages={formMessages.postal_code_city}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  {getText("l_room_code")}
                </label>
                <div className="mt-1 relative">
                  <p
                    className="block w-full border-gray-300 rounded-md focus:ring-[#E47E7B] focus:border-[#E47E7B]"
                    style={{ borderWidth: "1px", padding: "0.5rem 1rem" }}
                  >
                    {accomodation.room_code
                      ? accomodation.room_code
                      : getText("ph_no_room_code")}
                  </p>
                </div>
              </div>
            </div>
          </div>

          
          <div className="mt-8 flex justify-between">
          <button
              type="button"
              onClick={onDelete}
              disabled={!canDelete}
              className={"rounded-lg px-4 py-2 " + (canDelete ? "bg-red-100 text-red-700 hover:bg-red-200": "bg-red-50 text-red-500")}
            >
              Delete
            </button>

            <div className="mt-8 flex justify-end gap-4">
            

            </div>
            <button
              type="button"
              onClick={onClose}
              className="rounded-lg bg-gray-100 px-4 py-2 text-gray-700 hover:bg-gray-200"
            >
              Cancel
            </button>
            <button
              type="button"
              className={"rounded-lg bg-[#E76D6D] px-4 py-2 text-white " + (!saved ? "hover:bg-[#D65C5C]" : "")}
              disabled={saved}
              onClick={handleSave}
            >
              Save Property
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
