import React, { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../utils/AppContext";
import { useNavigate } from "react-router-dom";
import {
  isValidDateStr,
  notEmptyValidator,
  validateFormOptional,
  trimForm,
  validateFormOptionalExt,
  validateFormOptionalExtContext,
  validateFormOptionalContext,
  isCompared,
} from "../../../utils/FormManager";
import { MyLoading, MyModal } from "../../common/MyModal";
import { API_URLS } from "../../../utils/RequestManager";
import { WEB_ROUTERS } from "../../../utils/routes";
import { TextField } from "../../common/components/TextField";
import { PrimaryButton } from "../../common/components/PrimaryButton";
import { KeyRound, Mail } from "lucide-react";
import { DateField } from "../../common/components/DateField";

const name2validators = {
  room_code: [notEmptyValidator()],
  checkin_date: [
    isValidDateStr(),
    isCompared(
      "checkout_date",
      (lhs, rhs) => !lhs || !rhs || lhs <= rhs,
      "checkin date should not be greater than checkout date"
    ),
  ],
  checkout_date: [
    isValidDateStr(),
    isCompared(
      "checkin_date",
      (lhs, rhs) => !lhs || !rhs || lhs >= rhs,
      "checkout date should not be less than checkin date"
    ),
  ],
  email: [notEmptyValidator()],
};

const name2validatorsWithCode = {
  ...name2validators,
  code: [notEmptyValidator()],
};

interface IFormData {
  room_code: string;
  checkin_date: string;
  checkout_date: string;
  email: string;
  code: string;
}

type IFormMessages = { [key in keyof IFormData]: string[] };

interface IFindReservationProps {}
export const FindReservation: React.FC<IFindReservationProps> = () => {
  const {
    requestManager,
    user,
    setUser,
    getText,
    reservation,
    setReservation,
    reservationApplication,
    setReservationApplication,
  } = useContext(AppContext);

  const [formData, setFormData] = useState<IFormData>({
    room_code: "",
    checkin_date: "",
    checkout_date: "",
    email: "",
    code: "",
  });
  const [formMessages, setFormMessages] = useState<IFormMessages>({
    room_code: [],
    checkin_date: [],
    checkout_date: [],
    email: [],
    code: [],
  });

  const [modalText, setModalText] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // NOTE: clear existing reservation
    if (reservation) {
      setReservation(null);
    }
    if (reservationApplication) {
      setReservationApplication(null);
    }
  }, []);

  const checkData = () => {
    const data = { ...formData };
    trimForm(data);
    const [isValid, msgs] = validateFormOptionalExtContext(
      data,
      name2validators
    );
    setFormMessages((prev) => ({ ...prev, ...msgs }));
    if (!isValid) {
      return null;
    }
    return data;
  };
  const checkDataWithCode = () => {
    const data = { ...formData };
    trimForm(data);
    const [isValid, msgs] = validateFormOptionalExtContext(
      data,
      name2validatorsWithCode
    );
    setFormMessages((prev) => ({ ...prev, ...msgs }));
    if (!isValid) {
      return null;
    }
    return data;
  };

  const handleValidate = async () => {
    setLoading(true);
    if (user) {
      setUser(null);
    }
    const data = checkData();
    if (!data) {
      // setModalText(getText("m_res_not_found"));
      setLoading(false);
      return;
    }
    const data_reservation = {
      roat_number: data.room_code,
    };
    const resp_reservation = await requestManager.postJson(
      API_URLS.TOURIST_V2_RESERVATIONS_GET,
      data_reservation,
      false
    );
    if (resp_reservation.status === 200) {
      setReservation(resp_reservation.data);
    } else if (resp_reservation.status === 404) {
      setLoading(false);
      // alert("reservation not found");
      setModalText(getText("m_res_not_found"));
      return;
    } else {
      setLoading(false);
      // alert("error");
      setModalText(getText("m_res_error"));
      return;
    }

    const data_send_code = {
      email: data.email,
    };
    const resp_send_code = await requestManager.postJson(
      API_URLS.PASSWORD_GENERATE,
      data_send_code,
      false
    );
    if (resp_send_code.status === 200) {
      setModalText(getText("m_code_sent"));
    } else {
      setLoading(false);
      // alert("Error on sending code");
      setModalText(getText("m_code_send_failed"));
      return;
    }

    setLoading(false);
  };

  const handleCheckin = async () => {
    if (!reservation) {
      // alert("reservation not determined");
      setModalText(getText("m_validate_res"));
      return;
    }
    setLoading(true);
    const dataReservation = checkData();
    const dataCode = checkDataWithCode();
    if (!dataReservation || !dataCode) {
      setLoading(false);
      return;
    }

    const res = await requestManager.postJson(
      API_URLS.PASSWORD_VERIFY,
      dataCode,
      false
    );
    if (res.status !== 200) {
      setLoading(false);
      setModalText(getText("m_code_invalid"));
      return;
    }
    const loginRes = await requestManager.login(dataCode.email, dataCode.code);
    if (loginRes.status !== 200) {
      setLoading(false);
      setModalText(getText("m_failed_authorize"));
      return;
    }
    const data_reservation_application = {
      room_code: dataReservation.room_code,
      checkin_date: dataReservation.checkin_date,
      checkout_date: dataReservation.checkout_date,
    };
    const resp_reservation_application = await requestManager.postJson(
      API_URLS.TOURIST_V2_RESERVATION_APPLICATIONS_FIND_OR_CREATE,
      data_reservation_application
    );
    if (resp_reservation_application.status === 200) {
      setReservationApplication(resp_reservation_application.data);
    } else {
      setLoading(false);
      setModalText(getText("m_res_app_error"));
      return;
    }

    navigate(WEB_ROUTERS.TOURIST_GUESTS_STEPS);
    setLoading(false);
  };

  const handleChange = (name: string, value: string) => {
    const newData = { [name]: value };
    setFormData({ ...formData, ...newData });
    const newMsgs = validateFormOptionalContext(newData, name2validators);
    // console.log(newMsgs);
    setFormMessages({ ...formMessages, ...newMsgs });
  };

  return (
    <div className="page-content">
      <h3 className="header-tag">{getText("h_tourist_reservation")}</h3>
      <div style={{ display: "flex", gap: "40px", flexWrap: "wrap" }}>
        <div className="form-container w520_ifw">
          <div className="form-card">
            <div className="control__wrap">
              <div className="form__control">
                <TextField
                  label={getText("l_apartment_code")}
                  placeholder={getText("ph_apartment_code")}
                  value={formData.room_code}
                  onChange={(v) => handleChange("room_code", v)}
                  messages={formMessages.room_code}
                  icon={<KeyRound className="w-5 h-5" />}
                />
              </div>

              <div className="form__control">
                <DateField
                  label={getText("l_checkin")}
                  value={formData.checkin_date}
                  onChange={(v) => handleChange("checkin_date", v)}
                  messages={formMessages.checkin_date}
                />
                {/* <MyDateInput
                  label={getText("l_checkin")}
                  value={formData.checkin_date}
                  setValue={(v) => {
                    handleChange("checkin_date", v);
                  }}
                  validators={name2validators.checkin_date}
                  messages={formMessages.checkin_date || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, checkin_date: v })
                  }
                /> */}
              </div>

              <div className="form__control">
                <DateField
                  label={getText("l_checkout")}
                  value={formData.checkout_date}
                  onChange={(v) => handleChange("checkout_date", v)}
                  messages={formMessages.checkout_date}
                />
                {/* <MyDateInput
                  label={getText("l_checkout")}
                  value={formData.checkout_date}
                  setValue={(v) => handleChange("checkout_date", v)}
                  validators={name2validators.checkout_date}
                  messages={formMessages.checkout_date || []}
                  setMessages={(v) =>
                    setFormMessages({ ...formMessages, checkout_date: v })
                  }
                /> */}
              </div>

              <div className="form__control">
                <TextField
                  label={getText("l_email")}
                  placeholder={getText("ph_email")}
                  value={formData.email}
                  onChange={(v) => handleChange("email", v)}
                  messages={formMessages.email}
                  icon={<Mail className="h-5 w-5 text-gray-400" />}
                />
              </div>
            </div>
            <div className="control_center">
              <PrimaryButton handleClick={handleValidate}>
                {getText("btn_validate")}
              </PrimaryButton>
            </div>
          </div>

          <div className="form-card">
            <div className="control__wrap" style={{ alignItems: "center" }}>
              <div className="form__control">
                <TextField
                  label={getText("l_code")}
                  placeholder={getText("ph_code")}
                  value={formData.code}
                  onChange={(v) => handleChange("code", v)}
                  messages={formMessages.code}
                  icon={<KeyRound className="w-5 h-5" />}
                />
              </div>
              <div className="form__control">{getText("msg_code_sent")}</div>
            </div>
            <div className="control_center">
              <PrimaryButton handleClick={handleCheckin}>
                {getText("btn_start_checkin")}
              </PrimaryButton>
            </div>
          </div>
        </div>
        <div className="form-container">
          <div className="form-card">
            <h3 className="header">{getText("h_watch_checkin")}</h3>
            <div className="video-container video-container_medium">
              <iframe
                src="https://www.youtube.com/embed/mgY6Klox8Oc?si=0tcAHyGDcyrhzAS4"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
          <div
            className="header-tag"
            style={{ maxWidth: "472px", fontSize: "16px" }}
          >
            {getText("t_tourists_note")}
          </div>
        </div>
      </div>
      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      {loading && <MyLoading />}
    </div>
  );
};
