import React, { useContext, useState } from "react";
import { AppContext } from "../../utils/AppContext";

import { MyLoading, MyModal } from "../common/MyModal";
// import { CustomerRegister } from "./Register";
import { RegisterForm } from "./Auth/Register";
import { useNavigate } from "react-router-dom";
import { WEB_ROUTERS } from "../../utils/routes";
import { InfoDialog } from "./TouristRegistration/components/InfoDialog";

export const RegisterPage: React.FC<{}> = () => {
  const { getText } = useContext(AppContext);

  const [modalText, setModalText] = useState("");
  const [infoText, setInfoText] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
  };

  return (
    <div className="page-content">
      <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="text-center text-3xl font-bold text-[#E47E7B]">
            {getText("h_create_account")}
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <RegisterForm
              setModalText={setInfoText}
              setLoading={setLoading}
              onLoginClick={handleLoginClick}
            />
          </div>
        </div>
      </div>
      {modalText && (
        <MyModal>
          <div className="modal-text">{modalText}</div>
          <div className="btn bg_secondary" onClick={() => setModalText("")}>
            {getText("btn_ok")}
          </div>
        </MyModal>
      )}
      <InfoDialog
        isOpen={!!infoText}
        title="Information"
        message={infoText ?? ""}
        onClose={() => setInfoText(null)}
        onConfirm={() => setInfoText(null)}
      />
      {loading && <MyLoading />}
    </div>
  );
};
