import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../utils/AppContext";
import { MyLoading, MyModalV2 } from "../../common/MyModal";
import { API_URLS } from "../../../utils/RequestManager";
import { WEB_ROUTERS } from "../../../utils/routes";
import { InfoDialog } from "../TouristRegistration/components/InfoDialog";
import { IPayment, IReservation } from "../../common/types";
import { Logo } from "./Logo";
import { Plus, Search } from "lucide-react";
import { PropertyCardView } from "./PropertyCardView";
import { EditPropertyModal } from "./EditPropertyModal";
import { ServicesWidget } from "../../common/Stripe/ServicesWidget";

// const SUBSCRIBTION_IDX = -999;

export function PropertiesNew() {
  const {
    requestManager,
    user,
    setUser,
    accomodationId,
    setAccomodationId,
    getText,
  } = useContext(AppContext);

  const [accomodations, setAccomodations] = useState<Partial<IReservation>[]>(
    []
  );
  const [accomodationsRef, setAccomodationsRef] = useState<
    Partial<IReservation>[]
  >([]);
  const [searchTerm, setSearchTerm] = useState("");

  const [accomodationEditIdx, setAccomodationEditIdx] = useState<number | null>(
    null
  );

  const [payments, setPayments] = useState<IPayment[] | null>(null);

  const [deleteIdx, setDeleteIdx] = useState<number | null>(null);
  const [inactivateIdx, setInactivateIdx] = useState<number | null>(null);
  const [infoText, setInfoText] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [loading, setLoading] = useState(false);

  const [selectedProperty, setSelectedProperty] =
    useState<Partial<IReservation> | null>(null);
    
  const [showServices, setShowServices] = useState(false);

  const inProgress = useRef(false);

  const navigate = useNavigate();

  if (!user) {
    navigate(WEB_ROUTERS.CUSTOMER_LOGIN);
  }

  useEffect(() => {
    if (accomodationId !== null) {
      setAccomodationId(null);
    }
    refreshAccomodations();
    loadPayments();
  }, []);

  useEffect(() => {
    if (!accomodations.length || !payments) {
      return;
    }
    const activeAvailable = payments.reduce((s, p) => s + p.quantity, 0);
    const activeCount = accomodations.reduce(
      (s, a) => s + (a.active ? 1 : 0),
      0
    );
    if (activeCount > activeAvailable) {
      const msg = `The number of active properties (${activeCount}) exceeds the number of active subscriptions (${activeAvailable}). Disable some properties for your account functionality. If you just paid for a subscription, it may take some time for it to take effect.`;
      setBannerText(msg);
      setInfoText(msg);
    } else {
      setBannerText('');
    }
  }, [payments, accomodations]);

  const loadAccomodations = async () => {
    setLoading(true);
    const data = {};
    const res = await requestManager.postJson(
      API_URLS.MANAGER_V2_RESERVATIONS_GETALL,
      data
    );
    setLoading(false);
    if (res.status === 200) {
      const accs = res.data;
      setAccomodations(accs);
      setAccomodationsRef(accs.map((g: any) => ({ ...g })));
    } else {
      setInfoText(getText("m_res_app_error"));
    }
  };
  const loadPayments = async () => {
    setLoading(true);
    const res = await requestManager.postJson(
      API_URLS.MANAGER_V2_SUBSCRIPTION_GET,
      {}
    );
    setLoading(false);
    if (res.status === 200) {
      setPayments(res.data);
    } else {
      setPayments(null);
      setInfoText("Failed to fetch active payments");
    }
  };

  const refreshAccomodations = async () => {
    if (inProgress.current) return;
    inProgress.current = true;
    await loadAccomodations();
    inProgress.current = false;
  };

  const updateAccomodation = (idx: number, value: any) => {
    const newAccomodations = [
      ...accomodations.slice(0, idx),
      value,
      ...accomodations.slice(idx + 1),
    ];
    setAccomodations(newAccomodations);
  };

  const addAccomodation = async () => {
    const newAccomodation = {
      roat_number: "",
      address: "",
      postal_code_city: "",
      active: false,
    };
    const newAccomodations = [...accomodations, { ...newAccomodation }];
    const newAccomodationsRef = [...accomodationsRef, { ...newAccomodation }];
    // const lastIdx = newAccomodations.length - 1;
    setAccomodations(newAccomodations);
    setAccomodationsRef(newAccomodationsRef);
    // setSubscribeIdx(lastIdx);
  };
  const deleteAccomodationAsk = (idx: number) => {
    setDeleteIdx(idx);
  };
  const deleteAccomodationConfirm = async () => {
    if (deleteIdx === null) {
      return;
    }
    await deleteAccomodation(deleteIdx);
    setDeleteIdx(null);
  };
  const deleteAccomodation = async (idx: number) => {
    setLoading(true);
    const data = {
      id: accomodations[idx]["id"],
      valid: false,
    };
    let res = { status: 200 };
    if (data.id) {
      res = await requestManager.postJson(
        API_URLS.MANAGER_V2_RESERVATIONS_SAVE,
        data
      );
    }
    setLoading(false);
    if (res.status === 200) {
      setAccomodations([
        ...accomodations.slice(0, idx),
        ...accomodations.slice(idx + 1),
      ]);
      setAccomodationsRef([
        ...accomodationsRef.slice(0, idx),
        ...accomodationsRef.slice(idx + 1),
      ]);
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
  };

  const prepareAccomodation = (data: any) => {
    const res = {
      id: data.id,
      roat_number: data.roat_number.trim(),
      address: data.address.trim(),
      postal_code_city: data.postal_code_city.trim(),
      active: data.active,
    };
    return res;
  };

  const saveAccomodation = async (idx: number) => {
    setLoading(true);
    const data = prepareAccomodation(accomodations[idx]);
    const res = await requestManager.postJson(
      API_URLS.MANAGER_V2_RESERVATIONS_SAVE,
      data
    );
    if (res.status === 200) {
      const savedAccomodation = res.data;
      setAccomodations([
        ...accomodations.slice(0, idx),
        { ...savedAccomodation },
        ...accomodations.slice(idx + 1),
      ]);
      setAccomodationsRef([
        ...accomodationsRef.slice(0, idx),
        { ...savedAccomodation },
        ...accomodationsRef.slice(idx + 1),
      ]);
    } else if (res.status === 409) {
      setInfoText(getText("m_res_app_exist"));
    } else if (res.status === 400) {
      setInfoText(res.data?.message ?? "Failed to save reservation");
    } else {
      alert("Debug: " + JSON.stringify(res));
    }
    setLoading(false);
  };

  const checkSaved = (idx: number) => {
    const g = accomodations[idx];
    const gf = accomodationsRef[idx];
    if (!g || !gf || !gf.id) return false;
    for (const key of Object.keys(gf)) {
      if ((g as any)[key] !== (gf as any)[key]) {
        return false;
      }
    }
    return true;
  };

  const onStorageSelect = async (value: string) => {
    setLoading(true);
    // setStorage(value);
    const newUser = { ...user, storage: value };
    setUser(newUser);

    // const res = await requestManager.postJson(API_URLS.USER_UPDATE, newUser);
    const res = await requestManager.postJson(
      API_URLS.MANAGER_V2_USER_DATA_SAVE,
      newUser
    );
    setLoading(false);
    if (res.status === 200) {
      // setSubscribeIdx(-1);
    } else {
      setInfoText(getText("m_failed_change_storage"));
      // alert("Debug: " + JSON.stringify(res));
    }
  };

  // const onPaymentMethodSelect = async (value: string) => {
  //   setLoading(true);
  //   // setSubscribeIdx(-1);
  //   setLoading(false);
  // };

  const accomodationsSaved = accomodations.map((a, i) => checkSaved(i));

  // const handleRoatCredentials = () => {
  //   navigate(WEB_ROUTERS.CUSTOMER_ROAT);
  // };
  // const handleSubscribe = () => {
  //   setSubscribeIdx(SUBSCRIBTION_IDX);
  // };
  const filteredProperties = accomodations.map((accomodation) => {
    if (
      accomodation.address?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      accomodation.postal_code_city
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      accomodation.roat_number
        ?.toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      accomodation.room_code?.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return accomodation;
    }
    return null;
  });

  const handleToggleActive = (idx: number) => {
    const property = accomodations[idx];
    const propertyPayment = payments?.find((p) => p.option_id === property.id);
    if (!propertyPayment && !accomodations[idx].active) {
      setSelectedProperty(property);
      setShowServices(true);
    } else {
      // accomodations[idx].active = !accomodations[idx].active;
      // saveAccomodation(idx);
      setInactivateIdx(idx);
      setAccomodationEditIdx(null);
    }
    // if (!accomodations[idx].active && activeCount >= activeAvailable) {
    //   navigate(WEB_ROUTERS.SERVICES);
    //   return;
    // }

    // saveAccomodation(idx);
    // setAccomodationEditIdx(null);
  };
  const inactivateConfirm = () => {
    if (inactivateIdx === null) {
      return;
    }
    accomodations[inactivateIdx].active = false;
    saveAccomodation(inactivateIdx);
    setInactivateIdx(null);
  };
  const handleEdit = (idx: number) => {
    setAccomodationEditIdx(idx);
  };
  const handleNavigateToSubscribe = () => {
    navigate(WEB_ROUTERS.SERVICES);
  };

  const handleProceedSubscription = async (plan: string) => {
    if (!selectedProperty) {
      setInfoText("Select property to subscribe");
      return;
    }
    setLoading(true);
    const data = {
      plan: plan.toLowerCase(),
      option_id: selectedProperty.id,
    };
    const resp = await requestManager.postJson(
      API_URLS.MANAGER_V2_SUBSCRIPTION_INIT,
      data,
      true
    );
    setLoading(false);
    if (resp.status === 200) {
      const session = resp.data;
      // console.log(session);
      window.onbeforeunload = null;
      window.location.href = session.url;
      return true;
    } else {
      setInfoText && setInfoText("Failed initialize payment");
    }
  };

  return (
    // <div className="min-h-screen bg-gray-50">
    <div className="bg-gray-50 rounded-lg">
      <div className="mx-auto max-w-7xl px-4 py-4">
        {bannerText && <span
          className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium bg-red-100 text-red-800`}
        >
          {bannerText}
        </span>}
        {/* <div className="my-1 flex items-center gap-2">
          <span
            className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${
              activeCount <= activeAvailable
                ? "bg-green-100 text-green-800"
                : "bg-red-100 text-red-800"
            }`}
          >
            {`Active properties: ${activeCount} / ${activeAvailable}`}
          </span>
          {activeCount > activeAvailable ? (
            <button
              onClick={() => handleNavigateToSubscribe()}
              className={`flex items-center gap-2 rounded-lg px-4 py-2 text-sm font-medium transition-colors bg-green-50 text-green-700 hover:bg-green-100`}
            >
              Go to subscription page
            </button>
          ) : null}
        </div> */}
        <div className="flex items-center justify-between">
          <Logo />
          <button
            onClick={() => addAccomodation()}
            className="flex items-center gap-2 rounded-lg bg-[#E76D6D] px-4 py-2 text-white transition-colors hover:bg-[#D65C5C]"
          >
            <Plus className="h-5 w-5" />
            Add Property
          </button>
        </div>

        {/* Search Bar */}
        <div className="mt-8">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 h-5 w-5 -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search properties by name, HUT number, or internal code..."
              className="w-full rounded-lg border border-gray-300 bg-white py-2 pl-10 pr-4 text-gray-900 placeholder-gray-500 focus:border-[#E76D6D] focus:outline-none focus:ring-1 focus:ring-[#E76D6D]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>

        {/* Properties Grid */}
        <div className="mt-8 grid gap-6 md:grid-cols-2">
          {filteredProperties.map((accomodation, ai) =>
            accomodation ? (
              <PropertyCardView
                key={accomodation.id}
                accomodation={accomodation}
                saved={accomodationsSaved[ai]}
                onToggleActive={() => handleToggleActive(ai)}
                onEdit={() => handleEdit(ai)}
              />
            ) : null
          )}
        </div>

        {/* Empty State */}
        {filteredProperties.filter((acc) => acc).length === 0 && (
          <div className="mt-8 text-center">
            <p className="text-gray-500">
              {searchTerm
                ? "No properties found matching your search criteria."
                : 'No properties added yet. Click "Add Property" to get started.'}
            </p>
          </div>
        )}

        {accomodationEditIdx !== null && accomodations[accomodationEditIdx] && (
          <EditPropertyModal
            onClose={() => setAccomodationEditIdx(null)}
            idx={accomodationEditIdx}
            accomodation={accomodations[accomodationEditIdx]}
            setAccomodation={(val) =>
              updateAccomodation(accomodationEditIdx, val)
            }
            onSave={() => {
              saveAccomodation(accomodationEditIdx);
              setAccomodationEditIdx(null);
            }}
            onDelete={() => {
              deleteAccomodationAsk(accomodationEditIdx);
              setAccomodationEditIdx(null);
            }}
            saved={accomodationsSaved[accomodationEditIdx]}
            onBadSave={() => setInfoText(getText("m_some_fields_invalid"))}
          />
        )}
      </div>
      <MyModalV2 isOpen={showServices} onClose={() => setShowServices(false)}>
        <ServicesWidget
          loading={loading}
          handleProceed={handleProceedSubscription}
        />
      </MyModalV2>
      <InfoDialog
        isOpen={!!infoText}
        title="Information"
        message={infoText ?? ""}
        onClose={() => setInfoText("")}
        onConfirm={() => setInfoText("")}
      />
      <InfoDialog
        isOpen={deleteIdx !== null}
        title="Information"
        message={"Delete this property"}
        onClose={() => setDeleteIdx(null)}
        onConfirm={() => deleteAccomodationConfirm()}
      />
      <InfoDialog
        isOpen={inactivateIdx !== null}
        title="Confirm Deactivation"
        message={"Warning - if you cancel your subscription, you will not be charged for the next renewal period, but the service will be interrupted as soon as you confirm your cancellation. Please note that your travellers will no longer be able to check in and no data will be transmitted to the government server."}
        onClose={() => setInactivateIdx(null)}
        onConfirm={() => inactivateConfirm()}
      />
      {loading && <MyLoading />}
    </div>
  );
}
